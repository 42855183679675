// Modules.
import Vue from 'vue'

// Misc.
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n';

import '@/styles/index.scss';
import '@/styles/tailwind.css';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
