<template>
  <section class="flex flex-col h-screen">
    <Header />

    <Landing v-on:activate-questions="setQuestionsActive" />

    <transition
      appear
      name="slide-fade"
      mode="out-in"
    >
      <Questions
        v-if="showQuestions && index < questions.length"
        v-on:update-index="setIndex"
        v-on:update-value="setValue"
        v-on:update-option="setOption"
        :showQuestions="this.showQuestions"
        :questions="this.questions"
        :index="this.index"
        :value="this.value"
        :results="this.results"
      />
    </transition>

    <transition
      appear
      name="slide-fade"
      mode="in-out"
    >
      <Results
        :index="this.index"
        :option="this.option"
      />
    </transition>

  </section>

</template>

<script>
import Header from "@/components/Header";
import Landing from "@/components/Landing";
import Questions from "@/components/Questions";
import Results from "@/components/Results";

import preload from "@/preload";

export default {
  name: "Home",

  components: {
    Header,
    Landing,
    Questions,
    Results,
  },

  data() {
    return {
      index: 0,
      showQuestions: false,
      value: 0,
      questions: this.$t("questions"),
      results: this.$t("results"),
      option: {},
    };
  },

  mounted() {
    preload();
  },
  methods: {
    setIndex(number) {
      this.index = number;
    },
    setValue(number) {
      this.value = number;
    },
    setOption(results) {
      this.option = results;
    },
    setQuestionsActive(bool) {
      this.showQuestions = bool;
    },
  },
  computed: {
    backgroundImage() {
      return this.screenWidth <= 1024
        ? `url(${this.questions[this.index].img})`
        : `url(${this.questions[this.index].imgDesktop})`;
    },
  },
};
</script>

<style lang="scss">
.landing-img-mobile {
  position: relative;
  width: 300px;
  height: 359px;

  @media (min-width: theme("screens.tablet-up")) {
    display: none;
  }
}
.landing-img-desktop {
  position: relative;
  z-index: 10;
  width: 75%;
  height: auto;
  @media (max-width: theme("screens.tablet-less.max")) {
    display: none;
  }
}
.product-img {
  background-repeat: no-repeat;
}
.gold-line {
  width: 20px;
  height: 1px;
  left: 28px;
  top: 460px;
  background: #a89676;
}
.result-bg {
  position: relative;
  width: 200px;
  height: auto;
  top: 30px;
  right: 0;
}

.slide-fade-enter-active {
  transition: all 100ms linear;
}
.slide-fade-leave-active {
  transition: all 100ms linear;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
