// Modules.
import preloader from 'preloader';

export default function preload() {

    const loader = preloader({ xhrImages: false });

    loader.addImage('/images/products_desktop_Q.jpg');
    loader.addImage('/images/products_desktop_Q2.jpg');
    loader.addImage('/images/products_desktop_Q3.jpg');
    loader.addImage('/images/products_desktop_Q4.jpg');
    loader.addImage('/images/products_mobile_Q1.jpg');
    loader.addImage('/images/products_mobile_Q2.jpg');
    loader.addImage('/images/products_mobile_Q3.jpg');
    loader.addImage('/images/products_mobile_Q4.jpg');

    loader.addImage('/images/results_correct_mobile.png');
    loader.addImage('/images/results_prevent_fer_mobile.png');
    loader.addImage('/images/results_prevent_phl_mobile.png');
    loader.addImage('/images/results_prevent_sy_mobile.png');
    loader.addImage('/images/results_products_mobile.png');
    loader.addImage('/images/results_protect_mobile.png');

    loader.load();

}
