<template>
  <div
    v-if="index >= 4"
    class=" relative"
  >

    <div
      v-if="['a', 'b', 'c'].includes(option.answer)"
      class="results flex flex-col text-center font-futura-reg relative z-10 pt-6 text-white"
    >
      <div class="flex  relative justify-center tablet-less:hidden">

        <img
          class="header-desktop tablet-up:pb-6 tablet-less:hidden"
          src="/images/logo_desktop.svg"
          alt=""
        />
      </div>
      <div class="tablet-up:mx-64 font-futura-reg tablet-up:mt-12">
        <h1 class="text-5xl font-semibold tablet-less:text-3xl">
          {{ option.header }}
        </h1>
        <h2 class=" pt-4 mobile-less:mx-6">
          <p class="leading-9 tablet-less:leading-6 mobile-up:text-xl">This routine helps prevent, correct, and protect <br> your skin from damaging free-radicals.</p>
        </h2>
      </div>
      <div class="z-10 flex font-futura-reg justify-between mt-10 tablet-up:mt-16 text-white w-full tablet-up:mx-auto tablet-up:max-w-4xl">
        <div class="tablet-up:mx-6">
          <h3 class="text-lg tablet-up:text-2xl mb-2 tablet-up:mb-4 font-semibold">{{ option.productName1 }}</h3>
          <div class="pt-4">
            <img
              :src="option.productImage1"
              alt=""
            />
          </div>
          <div class="tablet-up:mx-16 tablet-less:mx-8">
            <a
              :href="option.productLink1"
              target="_blank"
              class="inline-block font-futura-reg cursor-pointer text-base mobile-less:text-sm flex w-full justify-center py-2 transform transition bg-transparent uppercase border border-gold-light outline-none tablet-up:hover:border-gold  tablet-up:hover:border-2 text-blue-dark"
            >
              Shop
            </a>
          </div>

          <div class="text-blue-dark mobile-less:text-xs text-base uppercase pt-2 underline ">
            <a
              :href="option.productLink1"
              target="_blank"
            >
              {{ option.productLinkCTA1 }}
            </a>
          </div>
        </div>
        <div class="tablet-up:mx-6">
          <h3 class="text-lg font-semibold tablet-up:text-2xl mb-2 tablet-up:mb-4">{{ option.productName2 }}</h3>
          <div class="pt-4">
            <img
              :src="option.productImage2"
              alt=""
            />
          </div>
          <div class="tablet-up:mx-16 tablet-less:mx-8">
            <a
              :href="option.productLink2"
              target="_blank"
              class="inline-block font-futura-reg cursor-pointer text-base mobile-less:text-sm flex w-full justify-center py-2 transform transition bg-transparent uppercase border border-gold-light outline-none tablet-up:hover:border-gold  tablet-up:hover:border-2 text-blue-dark"
            >
              Shop
            </a>
          </div>
          <div class="text-blue-dark mobile-less:text-xs text-base uppercase ">
            <a
              :href="option.productLink2"
              target="_blank"
            >

              <div class="pt-2 underline px-4">

                <p>Triple Lipid Restore 2:4:2<br /> & Retinols</p>
              </div>
            </a>
          </div>
        </div>
        <div class="tablet-up:mx-6">
          <h3 class="text-lg font-semibold tablet-up:text-2xl mb-2 tablet-up:mb-4">{{ option.productName3 }}</h3>
          <div class="pt-4">
            <img
              :src="option.productImage3"
              alt=""
            />
          </div>
          <div class="tablet-up:mx-16 tablet-less:mx-8">
            <a
              :href="option.productLink3"
              target="_blank"
              class="inline-block font-futura-reg cursor-pointer text-base mobile-less:text-sm flex w-full justify-center py-2 transform transition bg-transparent uppercase border border-gold-light outline-none tablet-up:hover:border-gold tablet-up:hover:border-4 text-blue-dark"
            >
              Shop
            </a>
          </div>
          <div class="text-blue-dark mobile-less:text-xs text-base uppercase">
            <a
              :href="option.productLink3"
              target="_blank"
            >

              <div class="pt-2 underline">

                <p>Physical Fusion UV <br /> Defense Sunscreen</p>
              </div>
            </a>

          </div>
        </div>
      </div>

    </div>
    <div
      v-if="option.answer == ['d']"
      class="question-four flex-col justify-center text-center"
    >
      <div class="flex pt-6  relative justify-center tablet-less:hidden">

        <img
          class="header-desktop tablet-less:hidden"
          src="/images/logo_desktop.svg"
          alt=""
        />
      </div>
      <div class="flex-col justify-center mx-8">
        <div class="
        flex-col
        tablet-up:mx-64 font-futura-reg mobile-less:pt-8 mobile-up:pt-10">

          <h1 class="text-5xl text-white text-center font-semibold tablet-less:text-3xl">
            {{ option.header }}
          </h1>
          <h2 class="leading-6 text-white text-center pt-4 text-base tablet-less:mx-4 ">
            {{ option.subHeader }}
          </h2>
        </div>
        <div class="flex justify-center mt-4"><img
            class="background-img relative"
            :src="option.productImage4"
            alt=""
          /></div>
      </div>
    </div>
    <div>
      <div class="tablet-less:px-8 mt-8 tablet-up:mx-auto tablet-up:max-w-lg tablet-up:mt-12">

        <div>
          <a
            class="tablet-up:transition tablet-up:duration-300 tablet-up:ease-in-out inline-block font-futura-reg font-bold cursor-pointer flex w-full justify-center py-2 tablet-up:hover:bg-gold-light tablet-up:hover:text-cream tablet-up:active:bg-gold uppercase text-lg border"
            :class="{'gold-light': clicked, 'flip-transparent': !clicked}"
            @click="activeButton(),deActiveButton(),delay(option.ctaLink)"
            target="_blank"
          >
            {{ option.shopCTA }}
          </a>
        </div>

        <div class="flex flex-col items-start font-futura-reg text-left pb-8">
          <div v-if="['a', 'b', 'c'].includes(option.answer)">
            <div
              class="text-gold-lighter pt-8 text-4xl font-semibold"
              :class="{ }"
            >
              <h2>{{ option.routineTitle }}</h2>
            </div>
            <div class="pt-1 pb-3">
              <div
                v-if="['a', 'b', 'c'].includes(option.answer)"
                class="gold-line"
              ></div>
            </div>
            <div class="text-black text-base leading-8">
              <p>{{ option.routineCopy }}</p>
            </div>
          </div>
          <div class="text-gold-lighter pt-6 text-4xl font-semibold">
            <h2>{{ option.personalizeTitle }}</h2>
          </div>
          <div class="pt-1 pb-3">
            <div class="gold-line"></div>
          </div>
          <div class="text-black text-black text-base leading-8">
            <p>{{ option.personalizeCopy }}</p>
          </div>
        </div>
        <div class="pb-20">
          <div>
            <a
              class="tablet-up:transition tablet-up:duration-300 tablet-up:ease-in-out inline-block font-futura-reg cursor-pointer flex w-full justify-center py-2 bg-transparent tablet-up:hover:bg-gold-light tablet-up:hover:text-cream  tablet-less:hover:text-cream tablet-up:active:bg-gold uppercase text-base text-gold tablet-less:hover:bg-gold border border-gold-light"
              :class="{'gold-light': clicked, 'transparent': !clicked}"
              @click="retakeQuiz()"
              id="btn_restart_quiz"
            >
              {{ $t("home.retake-button") }}
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "results",
  props: {
    index: Number,
    option: Object,
  },
  data() {
    return {
      timer: null,
      clicked: false,
    };
  },
  methods: {
    activeButton() {
      this.clicked = !this.clicked;
    },
    deActiveButton() {
      this.timer = setTimeout(() => {
        this.clicked = false;
      }, 250);
    },
    delay(URL) {
      setTimeout(function () {
        window.open(URL, "_blank");
      }, 500);
    },
    retakeQuiz() {
      this.timer = setTimeout(() => {
        location.reload();
        window.scrollTo(0, 0);
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.background-img {
  width: 210px;
  height: 300px;

  @media (min-width: 768px) {
    width: 280px;
    height: auto;
  }
}
.gold-light {
  background-color: theme("colors.gold");
  border-color: theme("colors.gold");
  color: theme("colors.cream");
}
.flip-transparent {
  background-color: transparent;
  border-color: theme("colors.gold-light");
  color: theme("colors.gold");
}
</style>