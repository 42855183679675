<template>
  <component
    :for="label"
    :is="type"
    :href="href"
    @click="activeButton(),deActiveButton() "
    class="tablet-up:transition tablet-up:duration-300 tablet-up:ease-in-out inline-block font-futura-reg cursor-pointer flex w-full justify-center py-2 tablet-up:hover:bg-gold-light tablet-up:active:bg-gold  uppercase border  tracking-wider text-base text-cream"
    :class="{'gold': clicked, 'transparent': !clicked}"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "btn",
  props: {
    label: String,
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      clicked: false,
      timer: null,
    };
  },
  methods: {
    activeButton() {
      this.clicked = true;
    },
    deActiveButton() {
      this.timer = setTimeout(() => {
        this.clicked = false;
      }, 250);
    },
  },
  computed: {
    type() {
      if (this.href) {
        return "a";
      } else {
        return "label";
      }
    },
  },
};
</script>

<style>
</style>
