<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    this.$root.$i18n.locale = this.$route.query.lang ?? "en";
  },
};
</script>

<style lang="scss">
@import "./styles/index";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
</style>
