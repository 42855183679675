<template>

  <div :class="['flex-col relative pb-0 ', !isActive ? 'landing' : '']">
    <div class="flex tablet-up:justify-center">
      <div
        v-if="start"
        class="relative z-10 flex tablet-less:items-center"
      >
        <div class="flex-col">
          <div class="flex relative justify-center">
            <!-- mobile -->
            <img
              class="header-desktop tablet-up:pt-20 tablet-less:hidden"
              src="/images/logo_desktop.svg"
              alt=""
            />
          </div>
          <div class="flex tablet-up:pt-20">
            <div class="flex-col tablet-up:w-1/2 tablet-up:pl-48 ">
              <h1 class="tablet-up:max-w-lg text-white text-5xl font-semibold tablet-up:leading-10 tablet-less:leading-7 tablet-less:text-3xl font-futura-reg mobile-less:pt-4 tablet-less:text-center tablet-less:px-9 tablet-less:pt-8">
                {{ $t("home.header") }}
              </h1>

              <div>
                <div class=" tablet-less:px-12">
                  <div class="mobile:px-6">
                    <div>
                      <h1 class="text-white text-base tablet-up:text-xl font-futura-reg tablet-less:text-center py-6 mobile-up:leading-8 tablet-up:pt-12 tablet-less:pt-4">
                        Discover Skinceuticals medical-grade <br> skincare tailored for you this season.
                      </h1>
                    </div>
                    <div class="tablet-up:w-3/5 flex items-center tablet-less:justify-center tablet-up:py-2 tablet-up:pt-6">
                      <a
                        id="btn_start_quiz"
                        @click="activeButton(),deActiveButton(), startQuiz()"
                        class="tablet-up:transition tablet-up:duration-300 tablet-up:ease-in-out inline-block font-futura-reg cursor-pointer flex w-full justify-center py-2 tablet-up:hover:bg-gold-light tablet-up:active:bg-gold uppercase border tracking-wider text-base text-cream"
                        :class="{'gold': clicked, 'transparent': !clicked}"
                      >
                        {{ $t("home.home-button") }}
                      </a>
                    </div>
                  </div>
                  <div class="
                        flex
                        pt-2
                        tablet-less:justify-center
                        tablet-less:pt-4">
                    <a
                      href="https://www.skinceuticals.ca/en/ceramide-and-retinol.html?utm_source=pinterest&utm_medium=social_post_paid&utm_content=skin_skfc_triple-lipid-restore-2:4:2_nativedisplay_aw&utm_campaign=skin_skfc_triple-lipid-restore-2:4:2_triplelipidrestore2022&utm_term=pinterest-extension-intro-screen-1"
                      class="flex justify-center text-white font-futura-reg font-semibold text-base underline tablet-up:text-base tablet-up:pt-4"
                      target="_blank"
                    >{{ $t("home.shop-now") }}</a>
                  </div>
                </div>
                <div class="flex justify-center pt-4">
                  <img
                    class="landing-img-mobile"
                    src=" /images/products_mobile_landing.png"
                    alt=""
                  />
                </div>
                <!-- desktop -->
              </div>
            </div>
            <div class="flex-col tablet-up:w-1/2">
              <div class="flex justify-center">
                <img
                  class="landing-img-desktop"
                  src="/images/products_desktop_landing.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import btn from "@/components/ui/Button";

export default {
  name: "Landing",

  components: {
    btn,
  },
  props: {
    showQuestions: Boolean,
  },
  data() {
    return {
      isActive: false,
      start: true,
      timer: null,
      clicked: false,
    };
  },

  methods: {
    activeButton() {
      this.clicked = !this.clicked;
    },
    deActiveButton() {
      this.timer = setTimeout(() => {
        this.clicked = false;
      }, 250);
    },
    startQuiz() {
      this.timer = setTimeout(() => {
        this.start = false;
        this.isActive = !this.isActive;
        this.$emit("activate-questions", true);
      }, 500);
    },
  },
};
</script>

<style>
.gold {
  background-color: theme("colors.gold");
  border-color: theme("colors.gold");
}
.transparent {
  background-color: transparent;
  border-color: theme("colors.gold-light");
}
</style>
