<template>
  <transition
    name="slide-fade"
    mode="out-in"
  >
    <div
      class="flex flex-grow tablet-up:justify-end tablet-less:flex-col tablet-up:overflow-hidden "
      :key="value"
    >

      <div class="questions-img tablet-up:hidden">
        <img
          :src="questions[index].img"
          alt=""
        />
      </div>
      <div class="flex fixed justify-end tablet-less:hidden">
        <img
          class="desktop-img fixed "
          :src="questions[index].imgDesktop"
          alt=""
        />
      </div>
      <div class="tablet-less:flex-col flex-grow tablet-up:flex-col questions tablet-up:pl-40 tablet-less:px-8 tablet-less:w-full">
        <div class="relative flex-col tablet-less:text-center">
          <div class="flex-col">
            <div class="flex pt-10 tablet-up:flex-col relative justify-start tablet-less:hidden">
              <!-- mobile -->
              <img
                class="header-desktop tablet-up:pb-20"
                src="/images/logo_desktop.svg"
                alt=""
              />
            </div>
            <div class="tablet-up:pt-20">
              <div class="flex-col">
                <div class="flex text-xs text-white font-futura-reg tablet-less:justify-center">
                  <p class="uppercase font-semibold">
                    {{ $t("home.count-header") }}
                    {{ index + 1 }}
                    of
                    {{ questions.length }}
                  </p>
                </div>
                <div class="flex pt-4 pb-3 tablet-less:justify-center">
                  <div class="gold-line"></div>
                </div>
                <h1 class="text-white font-semibold text-5xl pb-10 mobile:text-3xl font-futura-reg tablet-up:leading-10 tablet-up:w-2/5">
                  {{ questions[index]["question"] }}
                </h1>
              </div>

              <div class=" flex-col space-y-4 tablet-up:w-1/4">
                <btn
                  v-for="(choice, key) in questions[index]['choices']"
                  :key="key"
                  :label="key"
                >
                  <input
                    :id="key"
                    v-model="selectedChoice"
                    type="radio"
                    style="visibility: hidden"
                    :value="key"
                    @click="answered($event)"
                    :class="`ques_${ index + 1 }_ans_${ key }`"
                  />
                  {{ choice }}
                </btn>

                <div class="flex-col pt-10 micro:pt-10 pb-4">
                  <div class="mobile-less:text-center text-white underline-offset-2 font-futura-reg">
                    <button
                      @click="retakeQuiz"
                      id="btn_restart_quiz"
                      class="text-base underline"
                    >
                      {{ $t("home.retake-button") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import btn from "@/components/ui/Button";

export default {
  name: "Questions",
  components: {
    btn,
  },
  props: {
    showQuestions: Boolean,
    questions: Array,
    index: Number,
    value: Number,
    results: Array,
  },
  data() {
    return {
      screenWidth: 0,
      selectedChoice: "",
      choiceArr: [],
      timer: null,
    };
  },
  methods: {
    answered(event) {
      this.timer = setTimeout(() => {
        this.selectedChoice = event.target.value;
        this.choiceArr.push(this.selectedChoice);
        this.nextQuestion();
        if (this.index === this.questions.length - 1) {
          this.userResults();
        }
        window.scrollTo(0, 0);
      }, 250);
    },
    nextQuestion() {
      this.$emit("update-index", this.index + 1);
      this.$emit("update-value", this.value + 1);
    },
    userResults() {
      switch (this.choiceArr[2]) {
        case "a":
          this.$emit("update-option", this.results[0]);
          break;
        case "b":
          this.$emit("update-option", this.results[1]);
          break;
        case "c":
          this.$emit("update-option", this.results[2]);
          break;
        case "d":
          this.$emit("update-option", this.results[3]);
          break;
      }
    },
    retakeQuiz() {
      location.reload();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
.header-desktop {
  max-width: 252px;
  height: auto;
}
.desktop-img {
  width: 60vw;
  height: 100vh;
}
.mobile-img {
  width: 100vw;
  height: 100%;
}
.grow {
  flex-grow: 1;
}
</style>
