<template>
  <header class="relative z-10 bg-white w-full hero container mobile-less:pt-2 mobile-less:pb-2 flex items-center justify-center">
    <div>
      <!-- mobile -->
      <img
        class="relative z-10 header-img mobile-up:hidden"
        src="/images/logo_mobile.svg"
        alt=""
      />
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style>
.header-img {
  width: 200px;
  height: 28px;
}
</style>